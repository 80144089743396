import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import ImageModal from "../../containers/ImageModal"

const ThirdCol = styled(Col)`
  @media (max-width: 764px) {
    display: none;
  } ;
`

function Gallery({ galleryData }) {
  const col1 = []
  const col2 = []
  const col3 = []

  galleryData.forEach((element, key) => {
    const {
      id,
      tytul: title,
      zdjecie: { fluid: imageFluid },
    } = element

    if (key % 3 === 0) {
      col1.push(
        <Col xs={12} className="mt-1 p-2">
          <ImageModal imageFluid={imageFluid} title={title} key={id} />
        </Col>
      )
    }
    if (key % 3 === 1) {
      col2.push(
        <Col xs={12} className="mt-1 p-2">
          <ImageModal imageFluid={imageFluid} title={title} key={id} />
        </Col>
      )
    }
    if (key % 3 === 2) {
      col3.push(
        <Col md={12} className="mt-1 p-2">
          <ImageModal imageFluid={imageFluid} title={title} key={id} />
        </Col>
      )
    }
  })
  return (
    <Container className="mb-5">
      <Row className="mt-6 mb-5">
        <h1 className="page-header ml-auto mr-auto">Galeria</h1>
      </Row>
      <Row>
        <Col xs={6} md={4} key="first">
          <Row>{col1}</Row>
        </Col>
        <Col xs={6} md={4} key="second">
          <Row>{col2}</Row>
        </Col>
        <ThirdCol xs={4} key="third">
          <Row>{col3}</Row>
        </ThirdCol>
      </Row>
    </Container>
  )
}

export default Gallery

// import React from "react"
// import { createGlobalStyle } from "styled-components"
// import { Container, Row, Col, Pagination } from "react-bootstrap"

// import ImageModal from "../../containers/ImageModal"

// const PaginationStyles = createGlobalStyle`
//     a.page-link {
//         &:hover {
//             color: #e5007d;
//         }
//         color: #e5007d;
//     }

//     li.active span {
//         background-color: #e5007d!important;
//         border: 1px solid #e5007d!important;
//     }
// `

// function Gallery({ galleryData }) {
//   const [activeItem, setActiveItem] = React.useState(1)
//   const sliceStart = (activeItem - 1) * 6
//   const sliceEnd = sliceStart + 6
//   const images = []

//   galleryData.forEach(element => {
//     const {
//       id,
//       tytul: title,
//       zdjecie: { fluid: imageFluid },
//     } = element

//     images.push(
//       <Col xs={12} md={6} lg={4} className="mt-4">
//         <ImageModal imageFluid={imageFluid} title={title} key={id} />
//       </Col>
//     )
//   })

//   let items = []
//   for (let number = 1; number <= Math.ceil(images.length / 6); number++) {
//     items.push(
//       <Pagination.Item
//         key={number}
//         active={number === activeItem}
//         onClick={() => setActiveItem(number)}
//       >
//         {number}
//       </Pagination.Item>
//     )
//   }

//   return (
//     <Container className="mt-6 mb-6">
//       <PaginationStyles />
//       <Row>
//         <h1 className="page-header ml-auto mr-auto">Galeria</h1>
//       </Row>
//       <Row className="mt-5">{images.slice(sliceStart, sliceEnd)}</Row>
//       <Row className="mt-5">
//         {items.length > 1 && (
//           <Pagination className="ml-auto mr-auto">{items}</Pagination>
//         )}
//       </Row>
//     </Container>
//   )
// }

// export default Gallery
