import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Gallery from "../components/Gallery"

export const query = graphql`
  {
    allContentfulGalleryImage {
      nodes {
        tytul
        id
        zdjecie {
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default function GalleryPage({ data }) {
  const {
    allContentfulGalleryImage: { nodes: galleryData },
  } = data

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Galeria</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Gallery galleryData={galleryData} />
    </>
  )
}
